* {
  scroll-behavior: smooth;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.visible {
  opacity: 1;
}
