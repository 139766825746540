* {
  margin: 0;
}
main {
  height: 100vh;
}
.mainbody {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.loadingBackground {
  background: black;
  height: 100vh;
  width: 100vw;
}
.background {
  background: linear-gradient(black, transparent, black);
  position: absolute;
  background-size: auto;
  box-sizing: border-box;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  object-fit: cover;
}
.mainbody-wrapper {
  position: absolute;
  z-index: 5;
  width: 64rem;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  color: aliceblue;
}
.introduction h1 {
  font-size: 4rem;
  color: #b9deff;
  filter: drop-shadow(0 0 3px rgba(38, 154, 255, 0.677));
}
.introduction h2 {
  font-size: 3.1rem;
}
.introduction h3 {
  font-size: 2rem;
}
.introduction h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.introduction-link {
  border: 1px solid aliceblue;
  padding: 6px;
  height: 1rem;
  width: 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 50%;
  background-color: aliceblue;
}
.introduction-link:hover {
  transform: scale(1.1);
}
.introduction-link:active {
  transform: scale(0.9);
}
.introduction-links {
  display: flex;
  gap: 0.5rem;
}
.mainbody-span > a {
  text-decoration: none;
  color: #b9deff;
  filter: drop-shadow(0 0 3px #0088ff8c);
}
.scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.stats-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 25rem;
}
.stats-wrapper > h1 {
  position: absolute;
  top: 0;
  right: 15%;
}
.stats-projects,
.stats-contributions,
.stats-days,
.stats-repositories {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
  text-align: center;
}
.myStats {
  margin: 0 auto;
}
.graph {
  height: 60%;
  height: auto;
  width: 58%;
  filter: invert(1);
}
.stats {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.scroll-icon {
  width: 20px;
  height: 30px;
  border: 2px solid aliceblue;
  border-radius: 24px;
}
.scroll-icon:after {
  content: "";
  background-color: aliceblue;
  width: 4px;
  height: 6px;
  border-radius: 2px;
  animation: scroll 1000ms infinite alternate-reverse;
}
@keyframes scroll {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
@media (max-width: 64rem) {
  .mainbody {
    justify-content: center;
  }
  .mainbody-wrapper {
    width: 100%;
    justify-content: center;
  }
  .stats {
    justify-content: end;
  }
  .stats-wrapper {
    justify-content: end;
  }
  .stats-wrapper > h1 {
    display: none;
  }
  .introduction {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  .introduction h1 {
    font-size: 3rem;
  }
  .introduction h2 {
    font-size: 2.38rem;
  }
  .introduction h3 {
    font-size: 1.53rem;
  }
  .introduction h4 {
    font-size: 1.15rem;
    margin-bottom: 10px;
  }
  .stats-projects,
  .stats-contributions,
  .stats-days,
  .stats-repositories {
    font-size: 0.76rem;
  }
  .graph {
    height: auto;
    width: 14rem;
  }
}
@media (max-width: 764px) {
  .mainbody-wrapper {
    flex-direction: column;
    justify-content: space-around;
    height: 70%;
  }
  .stats-wrapper {
    height: 20%;
    width: 100%;
  }
  .graph {
    display: none;
  }
  .stats {
    width: 100%;
    border: 3px solid;
    border-image: linear-gradient(
        to right,
        transparent,
        rgb(229, 236, 243),
        transparent
      )
      100% 1;
  }
  .stats-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .introduction {
    max-width: 20rem !important;
    width: 100%;
  }
  .myStats {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .myStats {
    width: 100%;
  }
}
