.about {
  width: 64rem;
  border-radius: 1rem 1rem 0 0;
  margin: 0 auto;
  gap: 1rem;
  position: relative;
  padding: 2rem 0;
}
.about-content {
  z-index: 3;
}
.about-wrapper {
  overflow-x: hidden;
  flex-direction: column;
  padding: 8rem 0;
  background: linear-gradient(black, #101010);
}
.about-text {
  width: 50%;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  border-left: 4px solid;
  padding-left: 10px;
}
.descriptionMobile {
  display: none;
}
.technology__img {
  width: 70px;
  height: 70px;
  transition: all 200ms ease-in-out;
  object-fit: contain;
  filter: drop-shadow(0 0 1px aliceblue);
}
.technology {
  flex-direction: column;
}
.technology-imgs {
  gap: 1rem;
  flex-wrap: wrap;
}
.technology__img--wrapper {
  margin: 0 10px;
  flex-direction: column;
}
.technology__img:hover {
  transform: scale(0.9);
}
.technology__img:hover + h5 {
  opacity: 1;
  visibility: visible;
  transform: translateY(100%);
  transform: scale(1);
}
.technology__img--wrapper > h5 {
  opacity: 0;
  transition: all 200ms ease-in-out;
  color: aliceblue;
  margin-top: 5px;
  transform: translateY(-100%) scale(0.1);
  visibility: hidden;
}
.technology-stack {
  width: 45%;
}
@media (max-width: 64rem) {
  .about-content {
    width: calc(64rem / 1.3);
  }
  .about {
    width: 100%;
  }
  .technology__img {
    width: 50px;
  }
  .about-title {
    font-size: calc(1em / 1.3);
  }
  .about-para {
    font-size: calc(1rem / 1.3);
  }
}
@media (max-width: 764px) {
  .about-text {
    width: 80vw;
    margin: 0 auto;
    filter: none;
  }
  .technology-stack {
    width: 80vw;
  }
  .descriptionMobile {
    display: block;
  }
  .descriptionPc {
    display: none;
  }
  .about {
    flex-direction: column;
    width: 90%;
  }
  .about-wrapper {
    height: 100%;
  }
  .about-title {
    font-size: 1rem;
  }
  .about-para {
    font-size: 1rem;
  }
  .windows-wrapper button {
    padding: 0;
  }
  .input-wrapper {
    width: 90vw;
  }
}
@media (max-width: 480px) {
  .about-title {
    font-size: 0.8rem;
  }
  .technology > h1 {
    font-size: 1.5rem;
  }
  .about-para {
    font-size: 0.8rem;
  }
}
