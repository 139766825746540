.nav {
  position: absolute;
  width: 64rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
  background-color: rgb(249, 252, 255);
  border-radius: 1rem;
  top: 2vh;
  box-shadow: 0 0 5px 0 rgb(239, 243, 246);
  z-index: 99;
}
.nav-wrapper {
  width: 100%;
  display: flex;
  z-index: 99;
  justify-content: center;
}
.logo-wrapper {
  height: 60%;
  align-items: center;
  display: flex;
  position: relative;
}
.logo {
  width: auto;
  height: 100%;
  display: block;
}
.nav-ul {
  list-style: none;
  display: flex;
  font-weight: bolder;
  flex-direction: row;
}
.nav-ul > .nav-li:nth-of-type(4) > a {
  padding: 6px 14px;
  background-color: black;
  color: aliceblue;
  border-radius: 10px;
  border: 3px solid black;
  transition: all 200ms ease-in-out;
}
.nav-ul > .nav-li:nth-of-type(4):hover a {
  color: black;
  text-decoration: none;
  background: transparent;
}
.nav-li:nth-of-type(4):active a {
  background-color: transparent;
  box-shadow: 0 0 0 0 !important;
  color: black;
}
.hover__effect:hover:after {
  left: 0;
  width: 100%;
}
.hover__effect:after {
  background-color: #242424;
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  background-color: black;
  transition: all 300ms ease;
}
.nav-li a {
  position: relative;
  text-decoration: none;
  color: black;
}
.nav-li {
  margin: 0 10px;
  font-size: 1.2rem;
}
@media (max-width: 64rem) {
  .nav {
    width: 100%;
    top: 0;
    border-radius: 0;
  }
}
@media (max-width: 480px) {
  .nav {
    justify-content: space-around;
  }
  .nav-li {
    font-size: 0.7rem;
  }
  .nav {
    height: 4rem;
  }
  .nav-ul {
    padding: 0 0 0 10px;
  }
}
