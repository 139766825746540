footer {
  background-color: #242424;
  border-top: 1px solid rgba(240, 248, 255, 0.399);
  display: flex;
  justify-content: center;
}
.footer-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 5rem 0;
  align-items: center;
  flex-direction: column;
  width: 400px;
  max-width: 90%;
}
.footer-image img {
  height: 60px;
  filter: invert(1);
}
.footer-image:hover ~ .introduction-links > .introduction-link {
  opacity: 1;
}
.introduction-link-footer {
  color: aliceblue;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.introduction-link-footer svg {
  fill: white;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.hover__effect-footer:hover:after {
  left: 0;
  width: 100%;
}
.hover__effect-footer:after {
  background-color: aliceblue;
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  background-color: aliceblue;
  transition: all 300ms ease;
}
.introduction-links-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
}
