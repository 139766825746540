#contact {
  display: flex;
  width: 64rem;
  padding: 10px;
  position: relative;
  overflow: hidden;
  border-left: 3px solid white;
  padding-left: 20px;
  margin: 2px;
  animation: infinite ease-in-out 1000ms gradient,
    fadeIn forwards ease-in-out 500ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-wrapper {
  padding: 8rem 0;
  background: linear-gradient(#1f1f1f, #242424);
}
.sent-message {
  background-color: #242424;
  position: absolute;
  color: aliceblue;
  fill: aliceblue;
  height: 130%;
  width: 130%;
  left: 50%;
  z-index: 99;
  top: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: forwards ease-out 500ms submitMessage;
}
.loading-screen {
  animation: forwards ease-out 500ms submitMessage;
  position: absolute;
  fill: aliceblue;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 130%;
  z-index: 98;
  width: 130%;
  background-color: #242424;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-screen svg {
  height: 5rem;
  animation: infinite 2000ms linear spinner;
}
.sent-message div,
.loading-screen div {
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.sent-message h1 {
  font-size: 3rem;
}
.sent-message svg {
  height: 5rem;
  animation: forwards 1000ms ease-out checkmarkSpin;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes submitMessage {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes checkmarkSpin {
  0% {
    transform: rotate(90deg) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}
.contact-text {
  width: 90%;
  color: #e8f9fd;
}
.contact-text-title {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.contact-text-main {
  margin: 1rem 0;
}
.contact-text-ending {
  margin: 1rem 0;
}
.contact-text-ending h3,
.contact-text-title h4 {
  font-weight: lighter;
}
.contact-text-wrapper {
  width: 50%;
}
.email a {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  text-decoration: none;
  color: #e8f9fd;
}
.email {
  display: flex;
}
.email > a > svg {
  fill: #e8f9fd;
}
.contact-input-wrapper {
  width: 50%;
}
label {
  color: aliceblue;
}
.input-name-wrapper,
.input-email-wrapper,
.input-message-wrapper textarea {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 0;
  font-family: sans-serif;
}
.input-message-wrapper textarea,
.input-email-wrapper input,
.input-name-wrapper input {
  font-size: 1.1rem;
  padding-left: 0.3rem;
  transition: all 200ms ease-in-out;
}
.input-name-wrapper input,
.input-email-wrapper input {
  height: 2rem;
}
textarea {
  resize: none;
  width: 99%;
}
.input-email-wrapper input,
.input-message-wrapper textarea,
.input-name-wrapper input {
  background-color: rgb(229, 236, 243);
  border: none;
  border-radius: 5px;
}
.input-email-wrapper input:focus,
.input-message-wrapper textarea:focus,
.input-name-wrapper input:focus {
  outline: none;
  box-shadow: 0 0 10px 0 aliceblue;
}
.input-message-wrapper textarea {
  height: 6rem;
}
#unSent {
  height: 3rem;
  width: 100%;
  background: rgb(229, 236, 243);
  color: black;
  font-size: 1.2rem;
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
}
#unSent:hover {
  box-shadow: 0 0 5px 0 aliceblue;
  cursor: pointer;
}
@media (max-width: 764px) {
  #contact {
    flex-direction: column;
    gap: 4rem;
    margin: 1rem;
  }
  .contact-text-wrapper {
    width: 100%;
  }
  .contact-input-wrapper {
    width: 100%;
  }
  .sent-message > div > h1 {
    font-size: 8vw;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .contact-text-ending {
    display: none;
  }
}
